// Core components to be included with every app

// -------------------------
// DO NOT ADD NEW COMPONENTS WITHOUT CAREFUL DELIBERATION
// -------------------------
import type { App } from 'vue';

// Actions
import { OActionSave, OActionDelete, OActionCancel } from 'o365-data-components';
import {
    OColContainer, ORowContainer, ODatePicker, OSizerPanel, OTabs, OTab, OContentEditable,
    OTextEditor, ONumberEditor, OBitEditor, ODateEditor, ODropdown, OModal, OTextArea,
    OToolbarAction, OForm, OFormControl, OCheck, OLink, OButton, OSwitch
} from 'o365-ui-components';

// Layout

// import OHeigthSizer from 'o365.vue.components.HeigthSizer.vue';
// import OWidthSizer from 'o365.vue.components.WidthSizer.vue';

import ONavbar from 'o365.vue.components.ONavbar.vue';

// Data display
import { ODataGrid, OColumn, OColumnGroup } from 'o365-datagrid';

// Inputs
import { ODataLookup } from 'o365-datalookup';

// Filtering
import { OFilterString } from 'o365-filter-components';

export default {
    install: (app: App) => {
        // Component names must be in PascalCase for them to resolve both in pascal and kebab cases.

        // Actions
        app.component('OActionSave', OActionSave);
        app.component('OActionDelete', OActionDelete);
        app.component('OActionCancel', OActionCancel);

        // Layout
        // app.component('OContainer', OContainer);
        app.component('OColContainer', OColContainer);
        app.component('ORowContainer', ORowContainer);
        app.component('OSizerPanel', OSizerPanel);

        // app.component('OHeigthSizer', OHeigthSizer);
        // app.component('OWidthSizer', OWidthSizer);

        app.component('ONavbar', ONavbar);


        app.component('OTabs', OTabs);
        app.component('OTab', OTab);

        app.component('OForm', OForm);
        app.component('OFormControl', OFormControl);

        app.component('ODropdown', ODropdown);
        app.component('OModal', OModal);

        // Data display
        app.component('ODataGrid', ODataGrid);
        //app.component('ODataTable', ODataTable);

        app.component('OCheck', OCheck);
        app.component('OLink', OLink);
        app.component('OButton', OButton);
        app.component('OSwitch', OSwitch);

        app.component('OColumn', OColumn);
        app.component('OColumnGroup', OColumnGroup);

        // Inputs
        app.component('ODataLookup', ODataLookup);
        app.component('ODatePicker', ODatePicker);

        app.component('OToolbarAction', OToolbarAction);

        app.component('OTextEditor', OTextEditor);
        app.component('ONumberEditor', ONumberEditor);
        app.component('OBitEditor', OBitEditor);
        app.component('ODateEditor', ODateEditor);
        app.component('OContentEditable', OContentEditable);
        app.component('OTextArea', OTextArea);

        // Filtering
        app.component('OFilterString', OFilterString);

    }
} 

declare module '@vue/runtime-core' {
    export interface GlobalComponents {
        OActionSave: typeof OActionSave,
        OActionDelete: typeof OActionDelete,
        OActionCancel: typeof OActionCancel,
        OColContainer: typeof OColContainer,
        ORowContainer: typeof ORowContainer,
        OSizerPanel: typeof OSizerPanel,

        ONavbar: typeof ONavbar,

        OTabs: typeof OTabs,
        OTab: typeof OTab,
        OForm: typeof OForm,
        OFormControl: typeof OFormControl,
        OCheck: typeof OCheck
        OLink: typeof OCheck
        ODropdown: typeof ODropdown,
        OModal: typeof OModal,

        ODataGrid: typeof ODataGrid,
        OColumn: typeof OColumn,

        OTextEditor: typeof OTextEditor,
        ONumberEditor: typeof ONumberEditor,
        OBitEditor: typeof OBitEditor,
        ODateEditor: typeof ODateEditor,
        OContentEditable: typeof OContentEditable,
        OTextArea: typeof OTextArea,

        OFilterString: typeof OFilterString,

    }
}